import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { IParagraph } from "../../components/Article/types"
import {
  Article,
  Container,
  Head,
  SecondarySection,
} from "../../components/components"
import CallToAction from "../../widgets/CallToAction/CallToAction"

const article: IParagraph[] = [
  {
    subtitle: "Chancen",
    heading: "Wachsende Konkurrenz",
    image: "competition",
    paragraphs: [
      "Die Digitalisierung birgt sowohl Chancen als auch Gefahren, doch wie macht man sich ihre Vorzüge zunutze, um die eigene Wettbewerbsfähigkeit zu stärken?",
      "Erfolgsgeschichten von milliardenschweren Unternehmen, die sich quasi über Nacht den Status eines Big Players zu eigen machen, stehen an der Tagesordnung und bestätigen den Ruf der Digitalisierung, als Katalysator für das Unternehmenswachstum zu fungieren. Vor allem viele junge Unternehmen haben die Chancen der Digitalisierung erkannt und machen sich die daraus resultierenden Möglichkeiten zu Nutze, um ihre Visionen zu teilen und den Märkten ihre Anteile abzugewinnen. Mit innovativen Ideen und kreativen Lösungen drängen sie aggressiv in den Markt und rütteln die Machtverhältnisse durcheinander.",
      "Ob KMUs (kleine und mittlere Unternehmen) oder international agierende Konzerne - die Digitalisierung ermöglicht es allen Wettbewerbern, sich am Markt sowohl zu entfalten als auch neu zu definieren und öffnet die Tore für Dienstleistungen, Fertigungsprozesse und Services auf zuvor ungeahnten Ebenen. Auf der anderen Seite steht der Kunde vor einer schier unendlichen Auswahl an Anbietern und verfügt auch im privaten Sektor über eine wachsende Zahl an Möglichkeiten, den Markt sowie die Wettbewerber zu analysieren und kennenzulernen.",
    ],
  },
  {
    subtitle: null,
    heading: "Eigene Schwachstellen bekämpfen",
    image: null,
    paragraphs: [
      "In dieser schnelllebigen Welt lautet die Devise Schritt zu halten, um nicht den Faden zu verlieren, indem man sich auf seine Stärken beruft und Potenziale ausschöpft.",
      "Um eben jene Potenziale bestmöglich auszuschöpfen, gilt es bestehende Ressourcen effizient einzusetzen und sowohl smart als auch progressiv in neue zu investieren. In immer härter umkämpften Märkten mit stetig steigendem Wettbewerb sind zeit- und kostenintensive Abläufe Gift für Ihre Agilität, die dringend benötigt wird, um sich nicht mit starren, steifen Prozessen selbst zu lähmen. Erkennen und beseitigen Sie diese Ressourcenfresser schnell und sicher und investieren Sie in das Wachstum Ihres Unternehmens. Schnellere Ergebnisse bei gleichbleibender Qualität und einem geringeren Fehlerpotenzial ermöglichen es, standardisierte Prozesse zu vereinfachen und den Fokus auf profitablere Themengebiete zu richten.",
    ],
  },
  {
    subtitle: null,
    heading: "Unser Anteil an Ihrem Erfolg",
    image: null,
    paragraphs: [
      "Von Prozessoptimierung über Automatisierung bis hin zur schlichten Beschleunigung von betrieblichen Abläufen bietet sich eine Vielfalt an Ansätzen, um Ihnen als kompetenter Partner in puncto Prozessdigitalisierung beiseitezustehen. Profitieren Sie von unserem Know-How und unserer Erfahrung, um auch den zukünftigen Erfolg Ihres Unternehmens abzusichern und die Vorzüge der Digitalisierung in Ihren Arbeitsalltag und Ihrer Erfolgsgeschichte einfließen zu lassen.",
      "Darüber hinaus sind digitalisierte Prozesse transparent und erfassbar, somit können sie grundsätzlich jederzeit von überall nachempfunden werden. Mobilität, Akzessibilität und Transparenz sind die daraus folgenden Benefits, die Ihnen trotz Wachstums ermöglichen Ihre Flexibilität beizubehalten, um für die Herausforderungen, die der Markt bietet, vorbereitet zu sein. Nicht zuletzt die Covid-19-Krise hat deutlich den Handlungsbedarf aufgezeigt, der hier branchenübergreifend bestand und vielerorts noch heute besteht. Die Krise hat, wie jede Krise zuvor, viele Profiteure hervorgebracht und der Gesellschaft die Bedeutung von mobilem Arbeiten in unserem Zeitalter vorgeführt.",
    ],
  },
  {
    subtitle: null,
    heading: "Ergreifen Sie Ihre Chance",
    image: null,
    paragraphs: [
      "Sie kennen Ihre Branche am besten. Wir unterstützen Sie lediglich dabei, die Chancen zu erkennen und diese zu ergreifen, sodass Ihre Expertise den ihr zustehenden Einfluss entfalten kann. Dabei helfen wir Ihnen, neue Wege zu beschreiten oder auf bestehenden Wegen Hindernisse aus dem Weg zu räumen, damit Sie sich dem Ausbau Ihrer Marktposition widmen können.",
      "Wir zeigen Ihnen Möglichkeiten und Perspektiven auf, die in Verbindung mit damit einhergehenden Erkenntnissen, zur Entwicklung innovativer Konzepte oder der Erschließung neuer Märkte beitragen können. Mit Softwarelösungen, die so individuell sind wie Ihr Unternehmen, passen wir uns Ihren Bedürfnissen an und schließen die Lücken, die geschlossen werden müssen, um Sie ganzheitlich für den Wettbewerb zu wappnen. Definieren Sie Ihre Ziele und KPIs (Key Performance Indicators, zu Deutsch Leistungskennzahlen) und wir zeigen Ihnen die Wege auf, diese zu erreichen. Zögern Sie nicht uns zu kontaktieren, sodass wir gemeinsam die Möglichkeiten prüfen und Ihre Chancen ergreifen können.",
      "Um es mit den Worten Fontanes zu sagen: „Am Mute hängt der Erfolg“.",
    ],
  },
]

const StrenghtenCompetition = ({ data }: { data: any }) => {
  return (
    <>
      <Head pagePath="/chancen/wettbewerbsfaehigkeit-staerken" />
      <SecondarySection withArticle>
        <Container style={{ alignItems: "center", justifyContent: "center" }}>
          <BlogContainer>
            <Article article={article} image={data.competition.image} />
          </BlogContainer>
        </Container>
      </SecondarySection>
      <CallToAction type="customer" />
    </>
  )
}

export default StrenghtenCompetition

export const query = graphql`
  query {
    competition: file(relativePath: { eq: "stock/competition.jpg" }) {
      image: childImageSharp {
        gatsbyImageData(width: 1920, layout: FULL_WIDTH)
      }
    }
  }
`

const BlogContainer = styled(Container)`
  max-width: 860px !important;
`
