import React from "react"
import styled from "styled-components"
//@ts-ignore
import MidasTouch from "../../assets/images/backgrounds/adams.svg"
import {
  ButtonLink,
  Column,
  Container,
  Heading,
  Emphasized,
  Paragraph,
  Row,
} from "../../components/components"

interface Props {
  withArticle?: boolean
  type: "customer" | "hiring"
  style?: React.CSSProperties
}

const CallToAction: React.FC<Props> = ({ withArticle, type, style }) => {
  return (
    <CallToActionWrapper
      style={style}
      withArticle={withArticle}
      className="contact-section"
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "40px",
          paddingTop: "40px",
          zIndex: "2",
          height: "100%",
        }}
      >
        <Row style={{ flex: "0 0 auto", justifyContent: "center" }}>
          <Column
            // lg={9}
            style={{ justifyContent: "flex-start", textAlign: "center" }}
          >
            <Heading tag="h1" style={{ textAlign: "center" }}>
              {type === "customer" ? (
                <span>
                  Erschaffen Sie Ihre
                  <Emphasized> digitale Zukunft </Emphasized>neu.
                </span>
              ) : (
                "Auch Lust auf spannende Projekte?"
              )}
            </Heading>
          </Column>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Paragraph
            style={{ fontWeight: 400, textAlign: "center" }}
            textAlign="center"
          >
            {type === "customer" ? (
              <>
                Schließen Sie sich unseren zufriedenen
                <Colorize> Kunden</Colorize> und <Colorize>Partnern</Colorize>{" "}
                an, mit denen wir bereits zusammenarbeiten durften.
              </>
            ) : (
              "Dann bewirb dich jetzt und werde Teil unseres jungen und ambitionierten Teams!"
            )}
          </Paragraph>
        </div>

        <Row
          style={{
            marginTop: "1rem",
            flex: "0 0 auto",
            justifyContent: "center",
          }}
        >
          <Column style={{ justifyContent: "center", flexDirection: "row" }}>
            <ButtonLink
              cta
              to={"/kontakt"}
              htmlLinkHref={
                type === "hiring" ? "mailto:info@incoqnito.io" : undefined
              }
            >
              {" "}
              {type === "customer"
                ? "Jetzt Kontakt aufnehmen"
                : "Jetzt bewerben"}
            </ButtonLink>
          </Column>
        </Row>
      </Container>
      <Image
        src={MidasTouch}
        alt="Zwei abstrakt dargestellte Hände die aufeinander zeigen und sich fast an den Fingerspitzen berühren"
      />
    </CallToActionWrapper>
  )
}

const Image = styled.img`
  width: 100%;
  top: -400px;
  height: 1600px;
  position: absolute;
  z-index: -1;
`

const Colorize = styled.span`
  color: ${props => props.theme.palette.blue.vibrant};
`

const CallToActionWrapper = styled.div<any>`
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: black;
  background-size: cover;
  background-position: center 25%;
  width: 100%;
  height: 600px;
  padding-top: ${p => (p.withArticle ? "150px" : null)};
  align-items: center;
  margin-top: 6rem;

  a {
    align-self: center;
  }
`

export default CallToAction
